import { FC, useMemo } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Slide } from "react-awesome-reveal";

import { InfoWrapper } from "components/common/info-wrapper.component";
import { twMerge } from "tailwind-merge";
import { Contents } from "components/privacy/contents.component";
import { GeneralInfo } from "components/privacy/general-info.component";
import { PersonalInfo } from "components/privacy/personal-info.component";
import { Address } from "components/privacy/address.component";
import { AggregateApps } from "components/privacy/aggregate-apps.component";
import { Apps } from "components/privacy/apps.component";
import { SuccessfulConnection } from "components/privacy/successful-connection.component";
import { AggregateSum } from "components/privacy/aggregate-sum.component";
import { Summary } from "components/privacy/summary.component";
import { MediaStreamer } from "components/privacy/media-streamer.component";
import { Jurisdiction } from "components/privacy/jurisdiction.component";
import { Measures } from "components/privacy/measures.component";
import { DisablingCookies } from "components/privacy/disabling-cookies.components";
import { FastVPNCookies } from "components/privacy/fast-VPN-Cookies.component";
import { Identifiers } from "components/privacy/identifiers.component";
import { ThirdPartyWebsites } from "components/privacy/third-party-websites.component";
import { Restrictions } from "components/privacy/restrictions.component";
import { European } from "components/privacy/european.component";
import { Changes } from "components/privacy/changes.component";
import { Contact } from "components/privacy/contact.component";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const PrivacyPolicy: FC = () => {
    const { t } = useTranslation();

    const hostName = window.location.hostname;

    const defaultStyles = "text-base text-white-heaven font-outfit font-normal leading-6";

    const termsItems = [
        {
            header: "contents",
            content: <Contents styles={defaultStyles} />
        },
        {
            header: "generalInfo",
            content: <GeneralInfo styles={defaultStyles} />
        },
        {
            header: "personalInfo",
            content: <PersonalInfo styles={defaultStyles} />
        },
        {
            header: "address",
            content: <Address styles={defaultStyles} />
        },
        {
            header: "aggregateApps",
            content: <AggregateApps styles={defaultStyles} />
        },
        {
            header: "apps",
            content: <Apps styles={defaultStyles} />
        },
        {
            header: "successfulConnection",
            content: <SuccessfulConnection styles={defaultStyles} />
        },
        {
            header: "aggregateSum",
            content: <AggregateSum styles={defaultStyles} />
        },
        {
            header: "summary",
            content: <Summary styles={defaultStyles} />
        },
        {
            header: "mediaStreamer",
            content: <MediaStreamer styles={defaultStyles} />
        },
        {
            header: "jurisdiction",
            content: <Jurisdiction styles={defaultStyles} />
        },
        {
            header: "measures",
            content: <Measures styles={defaultStyles} />
        },
        {
            header: "disablingCookies",
            content: <DisablingCookies styles={defaultStyles} />
        },
        {
            header: "fastVPNCookies",
            content: <FastVPNCookies styles={defaultStyles} />
        },
        {
            header: "identifiers",
            content: <Identifiers styles={defaultStyles} />
        },
        {
            header: "thirdPartyWebsites",
            content: <ThirdPartyWebsites styles={defaultStyles} />
        },
        {
            header: "restrictions",
            content: <Restrictions styles={defaultStyles} />
        },
        {
            header: "european",
            content: <European styles={defaultStyles} />
        },
        {
            header: "changes",
            content: <Changes styles={defaultStyles} />
        },
        {
            header: "contact",
            content: <Contact styles={defaultStyles} />
        }
    ];

    const renderLi = ({ header, content }: LiItem) =>
        useMemo(
            () => (
                <Slide triggerOnce key={header}>
                    <li className="font-outfit text-white-heaven text-2xl font-semibold mb-8">
                        {t(`privacy.${header}`)}
                        {content}
                    </li>
                </Slide>
            ),
            []
        );

    return (
        <InfoWrapper header={t("privacy.header")}>
            <p className={twMerge("mb-8", defaultStyles)}>
                <Trans i18nKey="multiline">{t("privacy.understand")}</Trans>
            </p>
            <ul className="list-inside">
                {termsItems.map(renderLi)}
                <li>
                    <a
                        className={twMerge(defaultStyles, "text-primary underline")}
                        href={`mailto:support@${hostName}`}>
                        support@{hostName}
                    </a>
                </li>
            </ul>
        </InfoWrapper>
    );
};
