import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

const items = ["informationHandled", "intendedForAdults"];

export const Restrictions: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();

    const renderLi = (item: string) => (
        <li className={twMerge("mb-6 last:mb-0", styles)} key={item}>
            {t(`privacy.${item}`)}
        </li>
    );

    return <ul className="mt-3">{items.map(renderLi)}</ul>;
};
