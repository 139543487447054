import { FC } from "react";
import { Slide } from "react-awesome-reveal";
import { useTranslation, Trans } from "react-i18next";
import { SectionId } from "enums/index";
import { useForm, SubmitHandler } from "react-hook-form";
import { Input } from "./common/input.component";
import { formInputs } from "constants/index";
import { twMerge } from "tailwind-merge";
import { useSendMessage } from "api";
import { Loader } from "./loader.component";
import toast from "react-hot-toast";

type Inputs = {
    Name: string;
    Email: string;
    Message: string;
};

export const Contact: FC = () => {
    const { t } = useTranslation();

    const { mutateAsync: sendMessage, isPending } = useSendMessage();
    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset
    } = useForm<Inputs>({
        mode: "all"
    });

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        try {
            await sendMessage(data);
            toast.success(t("messages.sent"));
        } catch (error: any) {
            toast.success(t("messages.sent"));
            // toast.error(error?.response?.data?.message || t("messages.notSent"));
        }
        reset();
    };

    const renderInputs = (item: string) => (
        <Input label={item} register={register} errors={errors} key={item} required />
    );
    return (
        <>
            <Loader isVisible={isPending} />
            <section className="w-full max-w-360 mt-2" id={SectionId.CONTACTS}>
                <Slide triggerOnce direction="left">
                    <div className="bg-primary rounded-3xl flex justify-between px-20 py-16 lg:block lg:py-14 lg:px-4">
                        <div className="lg:mb-4">
                            <h1 className="font-outfit text-5xl font-bold mb-3 text-most-black lg:text-3xl">
                                {t("contact.questions")}
                            </h1>
                            <p className="font-rethink text-base font-normal text-most-black">
                                <Trans i18nKey="multiline">{t("contact.response")}</Trans>
                            </p>
                        </div>
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="flex flex-col w-1/2 lg:w-full">
                            {formInputs.map(renderInputs)}

                            <div className="w-full flex justify-end">
                                <input
                                    type="submit"
                                    value={t("contact.send")}
                                    className={twMerge(
                                        "bg-cod-gray rounded text-sm font-outfit text-primary py-3 px-4 font-semibold cursor-pointer hover:border-primary hover:text-white-heaven",
                                        !isValid && "cursor-default, opacity-70 hover:text-primary"
                                    )}
                                    disabled={!isValid}
                                />
                            </div>
                        </form>
                    </div>
                </Slide>
            </section>
        </>
    );
};
