export const HOME_PATH = "/";

export const LOGIN_PATH = "/portal";

export const CREATE_ACCOUNT_PATH = "/create-account";

export const TERMS = "/terms-of-service";

export const PRIVACY_POLICY = "/privacy-policy";

export const PAYMENT = "/payment";
