import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "./common/button.component";
import { CREATE_ACCOUNT_PATH } from "router/constants";
import { ButtonType } from "enums/index";
import { Zoom } from "react-awesome-reveal";
import { Secured } from "./common/secured.component";
import { HeaderList } from "./common/header-list.component";

export const Header: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = (path: string) => () => {
        navigate(path);
    };

    return (
        <header className="overflow-hidden flex flex-col items-center">
            <Zoom triggerOnce>
                <section className="bg-header bg-right-4 bg-no-repeat w-screen min-h-screen pt-64 px-24 max-w-360 flex lg:pt-32 lg:px-4 lg:block lg:bg-none lg:min-h-full">
                    <div>
                        <h1 className="text-white-heaven text-7xl font-outfit font-bold mb-2 leading-21.5 lg:text-4xl lg:text-center">
                            <Trans i18nKey="multiline">{t("header.unlock")}</Trans>
                        </h1>
                        <p className="text-white-heaven text-2xl font-outfit font-normal mb-8 lg:text-xl lg:text-center">
                            {t("header.fastestGrowing")}
                        </p>
                        <Button
                            buttonType={ButtonType.FILLED}
                            className="px-5 max-w-fit mb-20 lg:mb-8 lg:max-w-none lg:w-full lg:flex lg:justify-center"
                            onClick={handleClick(CREATE_ACCOUNT_PATH)}
                            arrow={true}>
                            {t("navigation.tryTest")}
                        </Button>
                        <HeaderList />
                    </div>
                    <div className="flex justify-center ml-72 mt-14 lg:ml-0 lg:bg-header lg:bg-cover lg:h-52">
                        <Secured />
                    </div>
                </section>
            </Zoom>
        </header>
    );
};
