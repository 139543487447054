import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { ServiceNotAvailableIcon } from "assets/icons/service-not-available.icon";
import { Button } from "./common/button.component";

import { useNavigate } from "react-router-dom";

export const ServiceNotAvailable: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/");
    };

    return (
        <section
            aria-label="Service Not Available"
            className="w-full flex flex-col items-center text-center py-36 md:py-20">
            <Zoom triggerOnce>
                <ServiceNotAvailableIcon className="md:w-full" />
                <h1 className="font-outfit text-4xl font-bold text-white-heaven mt-8 mb-2 md:text-2xl">
                    {t("serviceNotAvailable.notAvailable")}
                </h1>
                <p className="font-outfit text-base text-white-heaven mb-8 md:text-sm">
                    <Trans i18nKey="multiline">{t("serviceNotAvailable.weAreSorry")}</Trans>
                </p>
                <Button
                    onClick={handleClick}
                    className={
                        "text-primary border-primary mb-6 flex justify-center items-center hover:text-most-black hover:bg-primary"
                    }>
                    {t("serviceNotAvailable.goBack")}
                </Button>
            </Zoom>
        </section>
    );
};
