import { FC, SVGProps } from "react";

export const AndroidIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={32} {...props}>
        <path
            d="M1.33398 23.9996C1.53398 21.6218 2.26198 19.4329 3.51798 17.4329C4.77398 15.4329 6.44598 13.8441 8.53398 12.6663L6.06732 8.39961C5.93398 8.19961 5.90065 7.9885 5.96732 7.76628C6.03398 7.54405 6.17843 7.37739 6.40065 7.26628C6.57843 7.15516 6.77843 7.13294 7.00065 7.19961C7.22287 7.26628 7.40065 7.39961 7.53398 7.59961L10.0007 11.8663C11.9118 11.0663 13.9118 10.6663 16.0007 10.6663C18.0895 10.6663 20.0895 11.0663 22.0007 11.8663L24.4673 7.59961C24.6007 7.39961 24.7784 7.26628 25.0007 7.19961C25.2229 7.13294 25.4229 7.15516 25.6007 7.26628C25.8229 7.37739 25.9673 7.54405 26.034 7.76628C26.1007 7.9885 26.0673 8.19961 25.934 8.39961L23.4673 12.6663C25.5562 13.8441 27.2282 15.4329 28.4833 17.4329C29.7384 19.4329 30.4664 21.6218 30.6673 23.9996H1.33398ZM9.33398 20.3329C9.80065 20.3329 10.1953 20.1716 10.518 19.8489C10.8407 19.5263 11.0015 19.1321 11.0007 18.6663C10.9998 18.2005 10.8384 17.8058 10.5167 17.4823C10.1949 17.1587 9.80065 16.9978 9.33398 16.9996C8.86732 17.0014 8.47265 17.1627 8.14998 17.4836C7.82732 17.8045 7.66643 18.1987 7.66732 18.6663C7.66821 19.1338 7.82954 19.5285 8.15132 19.8503C8.4731 20.1721 8.86732 20.3329 9.33398 20.3329ZM22.6673 20.3329C23.134 20.3329 23.5287 20.1716 23.8513 19.8489C24.174 19.5263 24.3349 19.1321 24.334 18.6663C24.3331 18.2005 24.1718 17.8058 23.85 17.4823C23.5282 17.1587 23.134 16.9978 22.6673 16.9996C22.2007 17.0014 21.806 17.1627 21.4833 17.4836C21.1607 17.8045 20.9998 18.1987 21.0007 18.6663C21.0015 19.1338 21.1629 19.5285 21.4847 19.8503C21.8064 20.1721 22.2007 20.3329 22.6673 20.3329Z"
            fill="#FEFEFE"
            fillOpacity="0.4"
        />
    </svg>
);
