import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    styles: string;
}

export const Contents: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();
    const list = t("privacy.contentsList").split(";")

    const renderLi = (item: string) => (
        <li className={styles} key={item}>
            {item}
        </li>
    );

    return (
        <ul className="list-disc list-inside mt-4">{list.map(renderLi)}</ul>
    );
};
