import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
    header: string;
    text: string;
    icon: JSX.Element
}

export const WhyItem: FC<Props> = ({ header, text, icon }) => {
    const { t } = useTranslation();

    return (
        <li className="flex w-[45%] lg:w-auto">
            <div className="min-w-12 h-12 rounded flex justify-center items-center bg-cod-gray mr-3">
                {icon}
            </div>
            <section>
                <h1 className="font-outfit font-bold text-2xl mb-3 leading-7">{t(header)}</h1>
                <p className="font-rethink text-base font-normal">{t(text)}</p>
            </section>
        </li>
    );
};
