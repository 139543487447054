import { FC } from "react";
import { useTranslation, Trans } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

export const License: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();

    return (
        <p className={twMerge("mt-3", styles)}>
            <Trans i18nKey="multiline">{t("terms.compliance")}</Trans>
        </p>
    );
};
