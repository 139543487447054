import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

const texts = [
    "qualified",
    "authority",
    "conducted",
    "applicable",
    "respondent",
    "temporary",
    "provision"
];

export const Arbitration: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();

    const renderText = (text: string) => (
        <p className={twMerge("mt-6", styles)} key={text}>
            {t(`terms.${text}`)}
        </p>
    );

    return (
        <>
            <p className={twMerge("mt-3", styles)}>{t("terms.disputes")}</p>
            {texts.map(renderText)}
        </>
    );
};
