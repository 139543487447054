import { FC, useMemo } from "react";
import { Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import { LockIcon } from "assets/icons/lock.icon";
import { ClosedEyeIcon } from "assets/icons/closed-eye.icon";
import { StarIcon } from "assets/icons/star.icon";
import { LaptopIcon } from "assets/icons/laptop.icon";

interface Item {
    header: string;
    text: string;
    icon: JSX.Element;
}

const featuresArray: Item[] = [
    {
        header: "advanced.kill",
        text: "advanced.protect",
        icon: <LockIcon width={40} />
    },
    {
        header: "advanced.tunneling",
        text: "advanced.provides",
        icon: <LaptopIcon width={40} />
    },
    {
        header: "advanced.support",
        text: "advanced.maximize",
        icon: <StarIcon width={40} />
    },
    {
        header: "advanced.flexibility",
        text: "advanced.settings",
        icon: <ClosedEyeIcon width={40} />
    }
];

export const AdvancedFeatures: FC = () => {
    const { t } = useTranslation();
    const renderList = (item: Item) =>
        useMemo(
            () => (
                <li key={item.text} className="bg-cod-gray rounded-xl px-4 py-8 w-[23%] lg:w-full lg:mb-4 lg:last:mb-0">
                    <div className="mb-4">{item.icon}</div>
                    <h1 className="mb-3 text-white-heaven text-2xl font-outfit font-bold">
                        {t(item.header)}
                    </h1>
                    <p className="font-rethink text-white-heaven text-opacity-80 text-sm">
                        {t(item.text)}
                    </p>
                </li>
            ),
            [t]
        );

    return (
        <section className="w-full max-w-360 mt-2 pb-16">
            <Slide triggerOnce direction="left">
                <div className="bg-primary rounded-3xl px-20 py-16 lg:py-14 lg:px-4">
                    <h1 className="text-center text-5xl font-outfit font-bold mb-4 lg:text-4xl lg:text-center lg:mb-8">
                        {t("advanced.header")}
                    </h1>
                    <ul className="flex justify-between lg:block">{featuresArray.map(renderList)}</ul>
                </div>
            </Slide>
        </section>
    );
};
