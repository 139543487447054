import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

import { SectionId } from "enums";
import { BenefitsItemProps } from "types/index";

import StrictImage from "assets/images/strict.png";
import OpenSourceImage from "assets/images/openSource.png";
import EasyConfigImage from "assets/images/easyConfig.png";
import AdBlockingImage from "assets/images/adBlocking.png";
import SupportImage from "assets/images/support.png";
import FeaturesImage from "assets/images/features.png";

const benefitsArray: BenefitsItemProps[] = [
    {
        header: "benefits.headers.strict",
        text: "benefits.content.strict",
        image: StrictImage
    },
    {
        header: "benefits.headers.openSource",
        text: "benefits.content.openSource",
        image: OpenSourceImage
    },
    {
        header: "benefits.headers.easyConfig",
        text: "benefits.content.easyConfig",
        image: EasyConfigImage
    },
    {
        header: "benefits.headers.adBlocking",
        text: "benefits.content.adBlocking",
        image: AdBlockingImage
    },
    {
        header: "benefits.headers.support",
        text: "benefits.content.support",
        image: SupportImage
    },
    {
        header: "benefits.headers.features",
        text: "benefits.content.features",
        image: FeaturesImage
    }
];

export const Benefits: FC = () => {
    const { t } = useTranslation();

    const renderPricingItem = (item: BenefitsItemProps) => (
        <li className="bg-cod-gray flex flex-col items-center p-3 rounded-xl border border-thamar-black" key={item.header}>
            <img src={item.image} alt={t(item.header)} width={128} height={128} className="mb-8" />
            <>
                <h1 className="text-white-heaven text-2xl font-outfit text-start font-semibold mb-2 w-full">
                    {t(item.header)}
                </h1>
                <p className="text-white-heaven font-rethink text-base text-opacity-80">
                    {t(item.text)}
                </p>
            </>
        </li>
    );

    return (
        <section
            id={SectionId.BENEFITS}
            aria-label="Benefits"
            className="pb-16 relative overflow-hidden mt-2">
            <Zoom triggerOnce>
                <h1 className="font-outfit text-5xl font-bold mb-8 text-center text-white-heaven md:text-3xl">
                    {t("benefits.header")}
                </h1>
                <ul
                    aria-label="Benefits items"
                    className="list-none max-w-7xl w-full grid grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 gap-8 mb-14">
                    {benefitsArray.map(renderPricingItem)}
                </ul>
            </Zoom>
        </section>
    );
};
