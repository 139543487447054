import { FC, SVGProps } from "react";

export const ProtectIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg" width={18} {...props}>
        <path
            d="M7.04428 17.808C5.02021 17.3024 3.34912 16.1502 2.031 14.3515C0.712875 12.5528 0.0541054 10.5559 0.0546879 8.36082V3.07388L7.04428 0.473755L14.0339 3.07388V8.36082C14.0339 10.5565 13.3751 12.5537 12.0576 14.3524C10.74 16.1511 9.06894 17.303 7.04428 17.808ZM5.29689 12.6077H8.79168C9.03923 12.6077 9.24688 12.5245 9.41463 12.3581C9.58238 12.1917 9.66597 11.986 9.66538 11.741V9.14086C9.66538 8.89529 9.58151 8.68959 9.41376 8.52376C9.24601 8.35793 9.03865 8.27472 8.79168 8.27415V7.40744C8.79168 6.93074 8.62073 6.52281 8.27882 6.18364C7.93691 5.84447 7.5254 5.67459 7.04428 5.67402C6.56317 5.67344 6.15195 5.84331 5.81062 6.18364C5.4693 6.52397 5.29805 6.9319 5.29689 7.40744V8.27415C5.04934 8.27415 4.84198 8.35735 4.67481 8.52376C4.50764 8.69017 4.42377 8.89587 4.42319 9.14086V11.741C4.42319 11.9866 4.50706 12.1925 4.67481 12.359C4.84256 12.5254 5.04992 12.6083 5.29689 12.6077ZM6.17059 8.27415V7.40744C6.17059 7.16187 6.25446 6.95617 6.42221 6.79034C6.58996 6.62451 6.79732 6.5413 7.04428 6.54073C7.29125 6.54015 7.4989 6.62335 7.66723 6.79034C7.83557 6.95732 7.91915 7.16302 7.91798 7.40744V8.27415H6.17059Z"
            fill="#9686F6"
        />
    </svg>
);
