import { FC, SVGProps } from "react";

export const OperaIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={32} {...props}>
        <path
            d="M11.6133 8.48699C10.1373 10.227 9.18397 12.8017 9.1173 15.687V16.3137C9.1853 19.2003 10.1373 21.771 11.6133 23.5137C13.5253 25.9963 16.368 27.5723 19.5466 27.5723C21.4492 27.568 23.3077 26.9991 24.8866 25.9377C22.2843 28.2767 18.8626 29.4917 15.368 29.3177C11.9452 29.1556 8.7163 27.6816 6.35148 25.2019C3.98667 22.7221 2.66756 19.4269 2.66797 16.0003C2.66797 8.63499 8.6373 2.66699 16.0013 2.66699H16.052C19.3141 2.67607 22.4589 3.88428 24.888 6.06166C23.3068 5.00179 21.4475 4.43307 19.544 4.42699C16.368 4.42699 13.5253 6.00433 11.6106 8.48699H11.6133ZM29.3346 16.0003C29.337 17.877 28.9421 19.7328 28.1756 21.4459C27.4092 23.1589 26.2888 24.6902 24.888 25.939C21.468 27.6057 18.2813 26.4403 17.2253 25.7123C20.5853 24.9737 23.1226 20.9123 23.1226 16.0003C23.1226 11.087 20.5853 7.02699 17.2253 6.28966C18.28 5.56299 21.468 4.39633 24.888 6.06166C26.2889 7.31038 27.4094 8.84166 28.1759 10.5547C28.9423 12.2677 29.3372 14.1237 29.3346 16.0003Z"
            fill="#FEFEFE"
            fillOpacity="0.4"
        />
    </svg>
);
