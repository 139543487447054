import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

import { WindowsIcon } from "assets/icons/windows.icon";
import { MacosIcon } from "assets/icons/macos.icon";
import { LinuxIcon } from "assets/icons/linux.icon";
import { AndroidIcon } from "assets/icons/android.icon";
import { AppleIcon } from "assets/icons/apple.icon";
import { ChromeIcon } from "assets/icons/chrome.icon";
import { FirefoxIcon } from "assets/icons/firefox.icon";
import { OperaIcon } from "assets/icons/opera.icon";
import { ConsolesIcon } from "assets/icons/consoles.icon";
import { RoutersIcon } from "assets/icons/routers.icon";
import { SmartTvIcon } from "assets/icons/smart-tv.icon";

interface SupportItem {
    icon: JSX.Element;
    text: string;
}

const list: SupportItem[] = [
    {
        icon: <WindowsIcon />,
        text: "Windows"
    },
    {
        icon: <MacosIcon />,
        text: "macOS"
    },
    {
        icon: <LinuxIcon />,
        text: "Linux"
    },
    {
        icon: <AndroidIcon />,
        text: "Android"
    },
    {
        icon: <AppleIcon />,
        text: "Apple iOS"
    },
    {
        icon: <ChromeIcon />,
        text: "Chrome"
    },
    {
        icon: <FirefoxIcon />,
        text: "Firefox"
    },
    {
        icon: <OperaIcon />,
        text: "Opera"
    },
    {
        icon: <ConsolesIcon />,
        text: "Consoles"
    },
    {
        icon: <RoutersIcon />,
        text: "Routers"
    },
    {
        icon: <SmartTvIcon />,
        text: "Smart TVs"
    }
];

export const GamingSupport: FC = () => {
    const { t } = useTranslation();

    const renderSupportItems = (item: SupportItem) => (
        <li className="flex flex-col items-center justify-center mr-12 last:mr-0 lg:mx-5 lg:mt-10 lg:last:mr-4" key={item.text}>
            {item.icon}
            <p className="text-white-heaven text-sm font-rethink mt-5">{item.text}</p>
        </li>
    );

    return (
        <section
            aria-label="Support for gaming consoles and smart tv"
            className="pb-20 relative overflow-hidden mt-2 lg:w-full">
            <Zoom triggerOnce>
                <h1 className="font-outfit text-5xl font-bold mb-8 text-center text-white-heaven lg:text-4xl">
                    {t("gaming.header")}
                </h1>
                <ul className="flex w-full justify-between lg:flex-wrap">{list.map(renderSupportItems)}</ul>
            </Zoom>
        </section>
    );
};
