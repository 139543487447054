import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { PRIVACY_POLICY } from "router/constants";
import { Slide } from "react-awesome-reveal";

import { InfoWrapper } from "components/common/info-wrapper.component";

import { Acceptance } from "components/terms/acceptance.component";
import { Modification } from "components/terms/modification.component";
import { Privacy } from "components/terms/privacy.component";
import { Refund } from "components/terms/refund.component";
import { Acceptable } from "components/terms/acceptable.component";
import { License } from "components/terms/license.component";
import { Language } from "components/terms/language.component";
import { ThirdParty } from "components/terms/third-party.component";
import { Partner } from "components/terms/partner.component";
import { Disclaimers } from "components/terms/disclaimers.component";
import { Limitations } from "components/terms/limitations.component";
import { Indemnification } from "components/terms/indemnification.component";
import { ChoiceOfLaw } from "components/terms/choice-of-law.component";
import { Arbitration } from "components/terms/arbitration.component";
import { Assignment } from "components/terms/assignment.component";
import { FinalProvisions } from "components/terms/final-provisions.component";
import { Payment } from "components/terms/payment.component";

interface LiItem {
    header: string;
    content: JSX.Element;
}

export const TermsOfService: FC = () => {
    const { t } = useTranslation();

    const defaultStyles = "text-base text-white-heaven font-outfit font-normal leading-6";

    const termsItems = [
        {
            header: "acceptance",
            content: <Acceptance styles={defaultStyles} />
        },
        {
            header: "modification",
            content: <Modification styles={defaultStyles} />
        },
        {
            header: "privacy",
            content: <Privacy styles={defaultStyles} />
        },
        {
            header: "refund",
            content: <Refund styles={defaultStyles} />
        },
        {
            header: "acceptable",
            content: <Acceptable styles={defaultStyles} />
        },
        {
            header: "license",
            content: <License styles={defaultStyles} />
        },
        {
            header: "language",
            content: <Language styles={defaultStyles} />
        },
        {
            header: "thirdParty",
            content: <ThirdParty styles={defaultStyles} />
        },
        {
            header: "partner",
            content: <Partner styles={defaultStyles} />
        },
        {
            header: "disclaimers",
            content: <Disclaimers styles={defaultStyles} />
        },
        {
            header: "limitations",
            content: <Limitations styles={defaultStyles} />
        },
        {
            header: "indemnification",
            content: <Indemnification styles={defaultStyles} />
        },
        {
            header: "choiceOfLaw",
            content: <ChoiceOfLaw styles={defaultStyles} />
        },
        {
            header: "arbitration",
            content: <Arbitration styles={defaultStyles} />
        },
        {
            header: "assignment",
            content: <Assignment styles={defaultStyles} />
        },
        {
            header: "finalProvisions",
            content: <FinalProvisions styles={defaultStyles} />
        },
        {
            header: "payment",
            content: <Payment styles={defaultStyles} />
        }
    ];

    const renderLi = ({ header, content }: LiItem) =>
        useMemo(
            () => (
                <Slide triggerOnce key={header}>
                    <li className="font-outfit text-white-heaven text-2xl font-semibold mb-8">
                        {t(`terms.${header}`)}
                        {content}
                    </li>
                </Slide>
            ),
            []
        );
    return (
        <InfoWrapper header={t("terms.header")}>
            <p className={defaultStyles}>{t("terms.document")}</p>
            <p className={twMerge("mb-8", defaultStyles)}>
                {t("terms.agreeing")}
                <a href={PRIVACY_POLICY} className="text-primary underline">
                    {t("terms.privacy")}
                </a>
            </p>
            <div className="bg-brilliance bg-opacity-[12%] border border-brilliance border-opacity-[16%] rounded py-2 px-4 mb-8">
                <p className={defaultStyles}>{t("terms.charges")}</p>
            </div>
            <ul className="list-decimal list-inside">{termsItems.map(renderLi)}</ul>
        </InfoWrapper>
    );
};
