import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

export const European: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();
    const list = t("privacy.fulfilling").split(";");

    const renderLi = (item: string) => (
        <li className={twMerge("", styles)} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <p className={twMerge("mt-3 mb-6", styles)}>{t("privacy.committed")}</p>
            <ul className="mb-6 list-disc list-inside">
                <p className={styles}>{t("privacy.GDPR")}</p>
                {list.map(renderLi)}
            </ul>
            <p className={twMerge("mb-6", styles)}>
                <Trans i18nKey="multiline">{t("privacy.associated")}</Trans>
            </p>
            <p className={styles}>{t("privacy.exercise")}</p>
        </>
    );
};
