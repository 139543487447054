import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { Button } from "./button.component";
import { PackageItemProps } from "types";
import { ButtonType } from "enums";
import { CompleteArrowIcon } from "assets/icons/complete-arrow.icon";
import { StarIcon } from "assets/icons/star.icon";

interface Props extends PackageItemProps {
    onButtonClick: () => void;
}

export const PricingPackageItem: FC<Props> = ({ name, price, month, onButtonClick, list }) => {
    const { t } = useTranslation();
    const renderList = useCallback((item: string) => {
        return (
            <li
                className={twMerge(
                    "text-white-heaven font-outfit text-base flex items-center mb-4 last:mb-0",
                    month === 6 && "text-most-black"
                )}
                key={item}>
                <CompleteArrowIcon fill={month === 6 ? "#0A0A0A" : ""} className="mr-2" />
                {item}
            </li>
        );
    }, []);

    return (
        <li
            key={price}
            aria-label={t(name)}
            className={twMerge(
                "px-4 py-8 rounded-lg h-full border min-w-72",
                month === 6
                    ? "border-primary bg-primary hover:bg-medium-slate"
                    : "border-thamar-black bg-cod-gray hover:bg-thamar-black"
            )}>
            <h1
                className={twMerge(
                    "text-light text-opacity-70 text-base font-outfit font-medium mb-6 uppercase",
                    month === 6 && "text-most-black flex justify-between items-center"
                )}>
                {t(name, { month })}
                {month === 6 && (
                    <span className="bg-most-black rounded-full text-primary px-3 py-1 text-xs flex items-center normal-case font-medium">
                        <StarIcon width={12} className="mr-1" />
                        {t("pricing.popular")}
                    </span>
                )}
            </h1>
            <h2
                className={twMerge(
                    "text-light text-5xl font-outfit font-semibold mb-6",
                    month === 6 && "text-most-black"
                )}>
                ${price}
            </h2>
            <Button
                buttonType={ButtonType.OUTLINED}
                onClick={onButtonClick}
                className={twMerge(
                    "text-primary border-primary mb-6 flex justify-center items-center hover:text-most-black hover:bg-primary",
                    month === 6 &&
                    "text-white-heaven bg-most-black hover:bg-thamar-black hover:text-white-heaven"
                )}>
                {t("pricing.get")}
                {month ? t("pricing.monthPlan", { month }) : t("pricing.yearPlan")}
            </Button>

            <ul>{t(list).split(";").map(renderList)}</ul>
        </li>
    );
};
