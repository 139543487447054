import React, { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { NavigationLinkProps } from "types";

interface Props extends NavigationLinkProps {
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

export const NavigationLink: FC<Props> = ({
  id,
  name,
  href,
  className,
  onClick
}) => {
  const { t } = useTranslation();

  const itemStyles =
    "h-full flex justify-center items-center text-base text-white-heaven text-opacity-40 hover:text-opacity-100 text-nowrap font-normal leading-5 cursor-pointer duration-300";

  return (
    <li
      key={id}
      aria-label={`Link to ${t(name)}`}
      className={twMerge(!href && itemStyles, className)}
      onClick={onClick}
    >
      {href ? (
        <a
          href={href}
          rel="noreferrer noopener"
          className={twMerge(itemStyles, className, "py-0")}
        >
          {t(name)}
        </a>
      ) : (
        t(name)
      )}
    </li>
  );
};
