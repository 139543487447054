import { FC, useEffect } from "react";

import { IframeContainer } from "components/common/iframe-container.component";
import { LinkModule } from "enums";
import { generateLink } from "utils/generate-link";
import { createAccountIframePath } from "constants/index";

import { ServiceNotAvailable } from "components/service-not-available.component";

export const CreateAccountPage: FC = () => {
    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    const link = generateLink({
        module: LinkModule.CHECKOUT,
        additionalPath: createAccountIframePath
    });

    // return (
    //   <IframeContainer title="Create Account" link={link} id="createAccount" />
    // );
    return <ServiceNotAvailable />;
};
