import { FC, SVGProps } from "react";

export const LaptopIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={25} {...props}>
        <path
            d="M2.5 20V17H4.5V6C4.5 5.45 4.696 4.97933 5.088 4.588C5.48 4.19667 5.95067 4.00067 6.5 4H21.5V6H6.5V17H12.5V20H2.5ZM15.5 20C15.2167 20 14.9793 19.904 14.788 19.712C14.5967 19.52 14.5007 19.2827 14.5 19V9C14.5 8.71667 14.596 8.47933 14.788 8.288C14.98 8.09667 15.2173 8.00067 15.5 8H21.5C21.7833 8 22.021 8.096 22.213 8.288C22.405 8.48 22.5007 8.71733 22.5 9V19C22.5 19.2833 22.404 19.521 22.212 19.713C22.02 19.905 21.7827 20.0007 21.5 20H15.5Z"
            fill="#9686F6"
        />
    </svg>
);
