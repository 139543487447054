import { FC, SVGProps } from "react";

export const CompleteArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg" width={29} {...props}>
        <rect x="1.25" y="0.5" width="27" height="27" rx="13.5" stroke="#9686F6" />
        <g clipPath="url(#clip0_105_1322)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.523 10.5555C19.6636 10.6961 19.7426 10.8869 19.7426 11.0858C19.7426 11.2846 19.6636 11.4754 19.523 11.616L13.9015 17.2375C13.8272 17.3118 13.739 17.3708 13.642 17.411C13.5449 17.4512 13.4408 17.4719 13.3358 17.4719C13.2307 17.4719 13.1266 17.4512 13.0296 17.411C12.9325 17.3708 12.8443 17.3118 12.77 17.2375L9.97701 14.445C9.90538 14.3758 9.84824 14.2931 9.80894 14.2016C9.76963 14.1101 9.74894 14.0116 9.74808 13.9121C9.74721 13.8125 9.76619 13.7137 9.8039 13.6215C9.84161 13.5294 9.8973 13.4456 9.96772 13.3752C10.0381 13.3048 10.1219 13.2491 10.214 13.2114C10.3062 13.1737 10.405 13.1547 10.5046 13.1556C10.6041 13.1564 10.7026 13.1771 10.7941 13.2164C10.8856 13.2557 10.9683 13.3129 11.0375 13.3845L13.3355 15.6825L18.462 10.5555C18.5317 10.4858 18.6144 10.4305 18.7054 10.3928C18.7964 10.3551 18.894 10.3357 18.9925 10.3357C19.091 10.3357 19.1886 10.3551 19.2796 10.3928C19.3707 10.4305 19.4534 10.4858 19.523 10.5555Z"
                fill="#9686F6"
            />
        </g>
        <defs>
            <clipPath id="clip0_105_1322">
                <rect width="12" height="12" fill="white" transform="translate(8.75 8)" />
            </clipPath>
        </defs>
    </svg>
);
