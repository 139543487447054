import { FC, useEffect } from "react";
import SlidingPane from "react-sliding-pane";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import { Logo } from "./logo.component";
import { NavigationList } from "./navigation-list.component";
import { ManageAccountList } from "./manage-account-list.component";
import { SectionId } from "enums";

interface Props {
  activeLinkId: SectionId | null;
  onLinkClick: (id: SectionId) => void;
  isPanelOpen: boolean;
  onClose: () => void;
}

export const MenuSlidingPanel: FC<Props> = ({
  isPanelOpen,
  activeLinkId,
  onLinkClick,
  onClose,
}) => {
  useEffect(() => {
    if (isPanelOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [isPanelOpen]);

  return (
    <SlidingPane
      className={
        "hidden lg:flex lg:!w-[40%] sm:!w-full bg-thamar-black flex-col"
      }
      overlayClassName="hidden lg:block !bg-black !bg-opacity-30 z-30"
      isOpen={isPanelOpen}
      onRequestClose={onClose}
      hideHeader
      shouldCloseOnEsc
    >
      <div className="h-20 flex justify-end sm:justify-between items-center py-3">
        <Logo className="hidden sm:flex" />

        <div className="p-5 absolute top-7 right-2" onClick={onClose}>
          <FontAwesomeIcon
            icon={faTimes}
            className="text-2xl text-white hover:text-primary cursor-pointer"
          />
        </div>
      </div>

      <div className="flex-1 flex justify-center items-center">
        <NavigationList
          listClassName="flex flex-col my-10"
          itemClassName="font-outfit py-5 text-2xl leading-7"
          isFooter={true}
          onLinkClick={onLinkClick}
          activeLinkId={activeLinkId}
        />
      </div>

      <ManageAccountList
        listClassName="hidden sm:flex sm:flex-col sm:gap-y-5"
        buttonClassName="flex justify-center"
        onButtonClick={onClose}
      />
    </SlidingPane>
  );
};
