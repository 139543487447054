import { FC } from "react";

const ErrorPage: FC = () => {
    return (
        <>
            <h1>Error page</h1>
        </>
    );
};

export default ErrorPage;