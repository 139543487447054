import { RouterProvider } from "react-router-dom";
import { QueryClientProvider } from "@tanstack/react-query";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";

import "config/i18n";
import { router } from "router/main.router";
import { queryClient } from "config/query-client";

function App() {
    return (
        <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={router} />
                <Toaster position="top-right" reverseOrder={false} />
            </QueryClientProvider>
        </HelmetProvider>
    );
}

export default App;
