import { FC } from "react";
import { Header } from "components/header.component";
import { WhyFast } from "components/why-fast-vpn.component";
import { Pricing } from "components/pricing.component";
import { AdvancedFeatures } from "components/advanced-features.component";
import { Benefits } from "components/benefits";
import { GamingSupport } from "components/support-for-gaming.component";
import { Contact } from "components/contact-form.component";

export const Home: FC = () => {

    return (
        <>
            <Header />
            <main className="px-4 flex flex-col items-center justify-center">
                <WhyFast />
                <Pricing />
                <AdvancedFeatures />
                <Benefits />
                <GamingSupport />
                <Contact />
            </main>
        </>
    );
};
