import { FC, SVGProps } from "react";

export const AppleIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={32} {...props}>
        <path
            d="M22.7342 27.04C21.4275 28.3067 20.0009 28.1067 18.6275 27.5067C17.1742 26.8933 15.8409 26.8667 14.3075 27.5067C12.3875 28.3333 11.3742 28.0933 10.2275 27.04C3.72087 20.3333 4.68087 10.12 12.0675 9.74667C13.8675 9.84 15.1209 10.7333 16.1742 10.8133C17.7475 10.4933 19.2542 9.57333 20.9342 9.69333C22.9475 9.85333 24.4675 10.6533 25.4675 12.0933C21.3075 14.5867 22.2942 20.0667 26.1075 21.6C25.3475 23.6 24.3609 25.5867 22.7209 27.0533L22.7342 27.04ZM16.0409 9.66667C15.8409 6.69333 18.2542 4.24 21.0275 4C21.4142 7.44 17.9075 10 16.0409 9.66667Z"
            fill="#FEFEFE"
            fillOpacity="0.4"
        />
    </svg>
);
