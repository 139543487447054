import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

interface Props {
    styles: string;
}

export const Address: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();
    const list = t("privacy.addressList").split(";");

    const renderLi = (item: string) => (
        <li className={styles} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <ul className="list-disc list-inside mt-3">{list.map(renderLi)}</ul>
            <p className={styles}>
                <Trans i18nKey="multiline">{t("privacy.marketingInfo")}</Trans>
            </p>
        </>
    );
};
