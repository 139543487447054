import { FC, SVGProps } from "react";

export const PowerIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" width={30} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.1437 2.14286C17.1437 1.57454 16.918 1.02949 16.5161 0.627628C16.1143 0.225765 15.5692 0 15.0009 0C14.4326 0 13.8875 0.225765 13.4857 0.627628C13.0838 1.02949 12.858 1.57454 12.858 2.14286V13.0221C12.858 13.5905 13.0838 14.1355 13.4857 14.5374C13.8875 14.9392 14.4326 15.165 15.0009 15.165C15.5692 15.165 16.1143 14.9392 16.5161 14.5374C16.918 14.1355 17.1437 13.5905 17.1437 13.0221V2.14286ZM8.40731 6.555C8.85125 6.20406 9.13834 5.69176 9.20593 5.12992C9.27352 4.56808 9.11612 4.00231 8.76809 3.55609C8.42005 3.10987 7.90965 2.81944 7.34826 2.74818C6.78687 2.67691 6.22008 2.83061 5.7716 3.17571C3.30953 5.09777 1.50887 7.74046 0.620892 10.735C-0.267089 13.7296 -0.1981 16.9267 0.818233 19.8802C1.83457 22.8337 3.74754 25.3963 6.29023 27.2103C8.83292 29.0244 11.8785 29.9994 15.002 29.9994C18.1254 29.9994 21.171 29.0244 23.7137 27.2103C26.2564 25.3963 28.1694 22.8337 29.1857 19.8802C30.202 16.9267 30.271 13.7296 29.383 10.735C28.495 7.74046 26.6944 5.09777 24.2323 3.17571C23.7842 2.82591 23.2155 2.66845 22.6513 2.73798C22.087 2.8075 21.5735 3.09831 21.2237 3.54643C20.8739 3.99455 20.7165 4.56328 20.786 5.12749C20.8555 5.69171 21.1463 6.2052 21.5945 6.555C23.353 7.92796 24.639 9.81561 25.2732 11.9546C25.9074 14.0936 25.858 16.3771 25.132 18.4867C24.406 20.5963 23.0396 22.4266 21.2234 23.7223C19.4072 25.018 17.2319 25.7145 15.0009 25.7145C12.7699 25.7145 10.5945 25.018 8.77834 23.7223C6.96216 22.4266 5.59574 20.5963 4.86975 18.4867C4.14376 16.3771 4.0944 14.0936 4.72857 11.9546C5.36274 9.81561 6.6488 7.92796 8.40731 6.555Z"
            fill="#0A0A0A"
        />
    </svg>
);
