import { FC, SVGProps } from "react";

export const ClosedEyeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={25} {...props}>
        <path
            d="M12.33 9L15.5 12.16V12C15.5 11.2044 15.1839 10.4413 14.6213 9.87868C14.0587 9.31607 13.2956 9 12.5 9H12.33ZM8.03 9.8L9.58 11.35C9.53 11.56 9.5 11.77 9.5 12C9.5 12.7956 9.81607 13.5587 10.3787 14.1213C10.9413 14.6839 11.7044 15 12.5 15C12.72 15 12.94 14.97 13.15 14.92L14.7 16.47C14.03 16.8 13.29 17 12.5 17C11.1739 17 9.90215 16.4732 8.96447 15.5355C8.02678 14.5979 7.5 13.3261 7.5 12C7.5 11.21 7.7 10.47 8.03 9.8ZM2.5 4.27L4.78 6.55L5.23 7C3.58 8.3 2.28 10 1.5 12C3.23 16.39 7.5 19.5 12.5 19.5C14.05 19.5 15.53 19.2 16.88 18.66L17.31 19.08L20.23 22L21.5 20.73L3.77 3M12.5 7C13.8261 7 15.0979 7.52678 16.0355 8.46447C16.9732 9.40215 17.5 10.6739 17.5 12C17.5 12.64 17.37 13.26 17.14 13.82L20.07 16.75C21.57 15.5 22.77 13.86 23.5 12C21.77 7.61 17.5 4.5 12.5 4.5C11.1 4.5 9.76 4.75 8.5 5.2L10.67 7.35C11.24 7.13 11.85 7 12.5 7Z"
            fill="#9686F6"
        />
    </svg>
);
