import { FC } from "react";
import { LoaderIcon } from "assets/icons/loader.icon";

interface Props {
  isVisible: boolean;
}

export const Loader: FC<Props> = ({ isVisible }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur bg-black/10 z-20">
      <LoaderIcon />
    </div>
  );
};
