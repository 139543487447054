import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CREATE_ACCOUNT_PATH } from "router/constants";
import { PowerIcon } from "assets/icons/power.icon";
import { ProtectIcon } from "assets/icons/protect.icon";
import { twMerge } from "tailwind-merge";
import { Link } from "react-router-dom";

interface Props {
    className?: string;
}

export const Secured: FC<Props> = ({ className }) => {
    const { t } = useTranslation();

    return (
        <div className={twMerge('flex flex-col items-center', className)}>
            <div className="p-2 border border-primary border-dashed max-w-fit h-fit rounded-full">
                <Link
                    to={CREATE_ACCOUNT_PATH}
                    className="rounded-full bg-primary hover:bg-medium-slate hover:border-medium-slate w-16 h-16 flex justify-center items-center m-2 duration-300">
                    <PowerIcon />
                </Link>
            </div>
            <div className="rounded-full bg-primary backdrop-blur-md bg-opacity-15 flex items-center justify-center h-11 mt-3 px-4">
                <ProtectIcon />
                <span className="font-outfit text-primary font-semibold text-sm ml-2">
                    {t("header.secured")}
                </span>
            </div>
        </div>
    );
};
