import { FC, SVGProps } from "react";

export const SmartTvIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={32} {...props}>
        <path
            d="M13.3333 19.4357L20.7693 14.6663L13.3333 9.89701V19.4357ZM12 26.6663V23.9997H6.15467C5.54044 23.9997 5.028 23.7943 4.61733 23.3837C4.20667 22.973 4.00089 22.4601 4 21.845V7.48767C4 6.87345 4.20578 6.36101 4.61733 5.95034C5.02889 5.53967 5.54089 5.3339 6.15333 5.33301H25.8467C26.46 5.33301 26.972 5.53879 27.3827 5.95034C27.7933 6.3619 27.9991 6.87434 28 7.48767V21.8463C28 22.4597 27.7942 22.9721 27.3827 23.3837C26.9711 23.7952 26.4591 24.0006 25.8467 23.9997H20V26.6663H12ZM6.15467 22.6663H25.8467C26.0511 22.6663 26.2391 22.581 26.4107 22.4103C26.5822 22.2397 26.6676 22.0512 26.6667 21.845V7.48767C26.6667 7.28234 26.5813 7.0939 26.4107 6.92234C26.24 6.75079 26.052 6.66545 25.8467 6.66634H6.15333C5.94889 6.66634 5.76089 6.75167 5.58933 6.92234C5.41778 7.09301 5.33244 7.28145 5.33333 7.48767V21.8463C5.33333 22.0508 5.41867 22.2388 5.58933 22.4103C5.76 22.5819 5.948 22.6672 6.15333 22.6663"
            fill="#FEFEFE"
            fillOpacity="0.4"
        />
    </svg>
);
