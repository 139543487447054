import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

interface Item {
    header?: string;
    text: string;
}

const content = [
    {
        text: "typesOfInfo"
    },
    {
        header: "related",
        text: "collected"
    },
    {
        header: "connection",
        text: "minimal"
    },
    {
        header: "diagnostics",
        text: "crashReports"
    },
    {
        header: "users",
        text: "provide"
    }
];

export const GeneralInfo: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();

    const renderLi = ({ header, text }: Item) => (
        <li key={text}>
            {header && <h2 className="mb-3 text-xl font-semibold font-outfit">{t(`privacy.${header}`)}</h2>}
            <p className={twMerge("mb-3", styles)}>{t(`privacy.${text}`)}</p>
        </li>
    );

    return <ul className="mt-4">{content.map(renderLi)}</ul>;
};
