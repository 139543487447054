import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

export const Refund: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();
    const hostName = window.location.hostname;

    return (
        <>
            <p className={twMerge("mt-3", styles)}>{t("terms.cancelAccount")}</p>
            <p className={twMerge("mt-6", styles)}>{t("terms.beyond")}</p>
            <p className={twMerge("mt-6", styles)}>
                {t("terms.generally")}
                <a className="underline text-primary" href={`mailto:support@${hostName}`}>
                    support@{hostName}
                </a>
            </p>
        </>
    );
};
