import { FC, useEffect } from "react";

import { IframeContainer } from "components/common/iframe-container.component";
import { LinkModule } from "enums";
import { generateLink } from "utils/generate-link";
import { ServiceNotAvailable } from "components/service-not-available.component";

export const PaymentPage: FC = () => {
    const params = window.location.search;
    const link = generateLink({
        module: LinkModule.PUBLIC,
        additionalPath: "/api/public/getPaymentLink"
    });

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, []);

    // return <IframeContainer title="Payment" link={`${link}/${params}`} id="payment" />;
    return <ServiceNotAvailable />;
};
