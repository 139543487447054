import { FC, useMemo } from "react";
import { Slide } from "react-awesome-reveal";
import { WhyItem } from "./common/why-we-item.component";
import { useTranslation } from "react-i18next";
import { LockIcon } from "assets/icons/lock.icon";
import { ClosedEyeIcon } from "assets/icons/closed-eye.icon";
import { StarIcon } from "assets/icons/star.icon";
import { LaptopIcon } from "assets/icons/laptop.icon";
import { SectionId } from "enums/index";

interface Item {
    header: string;
    text: string;
    icon: JSX.Element;
}

const whyFastArray: Item[] = [
    {
        header: "whyFast.headers.unbreakable",
        text: "whyFast.content.unbreakable",
        icon: <LockIcon />
    },
    {
        header: "whyFast.headers.access",
        text: "whyFast.content.access",
        icon: <LaptopIcon />
    },
    {
        header: "whyFast.headers.unlimited",
        text: "whyFast.content.unlimited",
        icon: <StarIcon />
    },
    {
        header: "whyFast.headers.anonymously",
        text: "whyFast.content.anonymously",
        icon: <ClosedEyeIcon />
    }
];

export const WhyFast: FC = () => {
    const { t } = useTranslation();
    const renderList = (item: Item) =>
        useMemo(
            () => (
                <WhyItem header={item.header} text={item.text} icon={item.icon} key={item.header} />
            ),
            [t]
        );

    return (
        <section className="w-full max-w-360 mt-2" id={SectionId.WHYWE}>
            <Slide triggerOnce direction="left">
                <div className="bg-primary rounded-3xl flex px-20 py-16 lg:block lg:py-14 lg:px-4">
                    <h1 className="font-outfit text-5xl font-bold min-w-1/3 lg:max-w-full lg:text-4xl lg:text-center lg:mb-8">
                        {t("whyFast.why")}
                    </h1>
                    <ul className="flex flex-wrap [&>*:nth-child(odd)]:mr-10 [&>*:nth-child(1)]:mb-10 [&>*:nth-child(2)]:mb-10 lg:[&>*:nth-child(odd)]:mr-0 lg:[&>*:nth-child(3)]:mb-10 lg:block">
                        {whyFastArray.map(renderList)}
                    </ul>
                </div>
            </Slide>
        </section>
    );
};
