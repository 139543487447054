import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

export const Modification: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();

    return (
        <p className={twMerge("mt-3", styles)}>{t("terms.update")}</p>
    );
};
