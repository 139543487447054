import { FC, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { ButtonToTop } from "./button-to-top.component";

interface Props extends PropsWithChildren {
    className?: string;
    header: string;
}

export const InfoWrapper: FC<Props> = ({ className, header, children }) => {
    return (
        <>
            <main
                className={twMerge(
                    "w-full flex flex-col px-56 pt-36 pb-16 justify-center mt-2 lg:px-4 lg:pb-0",
                    className
                )}>
                <h1 className="text-white-heaven font-outfit text-5xl font-bold mb-8">{header}</h1>
                <section className="max-w-360 w-[70%] relative lg:w-full">{children}</section>
                <ButtonToTop />
            </main>
        </>
    );
};
