import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

const items = [
    "collectMinimal",
    "anonymousApp",
    "shareInformation",
    "speedTest",
    "mac",
    "linux",
    "ios",
    "android",
    "browser",
    "uponActivation"
];

export const Summary: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();

    const renderLi = (item: string) => (
        <li className={twMerge("mb-6 last:mb-0", styles)} key={item}>
            <Trans i18nKey="multiline">{t(`privacy.${item}`)}</Trans>
        </li>
    );

    return <ul className="mt-3">{items.map(renderLi)}</ul>;
};
