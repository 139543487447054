import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

const texts = ["whenever", "understand", "provideBest", "protect"]

export const Acceptable: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();
    const list = t("terms.unsolicited").split("+")

    const renderText = (text: string) => (
        <p className={twMerge("mt-6", styles)} key={text}>{t(`terms.${text}`)}</p>
    )

    const renderLi = (item: string) => (
        <li className={styles} key={item}>{item}</li>
    )

    return (
        <>
            <p className={twMerge("mt-3", styles)}>
                {t("terms.accessed")}
                <a className="underline text-primary" href="/">
                    {t("terms.exportControl")}
                </a>
            </p>
            {texts.map(renderText)}
            <div className="bg-brilliance bg-opacity-[12%] border border-brilliance border-opacity-[16%] rounded py-2 px-4 mt-3">
                <h1 className={twMerge("text-base font-semibold mb-2", styles)}>{t("terms.inUsing")}</h1>
                <ul className="list-disc list-inside">
                    {list.map(renderLi)}
                </ul>
            </div>
        </>
    );
};
