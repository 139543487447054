import { FC, SVGProps } from "react";

export const StarIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={24} {...props}>
        <path
            d="M8.24228 7.33998L1.86228 8.26498L1.74928 8.28798C1.57822 8.3334 1.42227 8.42339 1.29737 8.54879C1.17247 8.67418 1.08308 8.83047 1.03834 9.00171C0.993598 9.17295 0.995105 9.35299 1.04271 9.52346C1.09031 9.69392 1.18229 9.8487 1.30928 9.97198L5.93128 14.471L4.84128 20.826L4.82828 20.936C4.81781 21.1129 4.85454 21.2894 4.93473 21.4475C5.01491 21.6055 5.13566 21.7395 5.28461 21.8355C5.43356 21.9315 5.60537 21.9863 5.78243 21.9941C5.95949 22.0019 6.13544 21.9625 6.29228 21.88L11.9983 18.88L17.6913 21.88L17.7913 21.926C17.9563 21.991 18.1357 22.0109 18.311 21.9837C18.4863 21.9566 18.6513 21.8832 18.7889 21.7712C18.9265 21.6593 19.0318 21.5127 19.0941 21.3466C19.1564 21.1805 19.1734 21.0008 19.1433 20.826L18.0523 14.471L22.6763 9.97098L22.7543 9.88598C22.8657 9.74875 22.9388 9.58444 22.966 9.40978C22.9932 9.23512 22.9737 9.05636 22.9094 8.89171C22.845 8.72707 22.7382 8.58242 22.5998 8.4725C22.4613 8.36258 22.2962 8.29132 22.1213 8.26598L15.7413 7.33998L12.8893 1.55998C12.8068 1.39252 12.679 1.2515 12.5205 1.15289C12.3619 1.05428 12.179 1.00201 11.9923 1.00201C11.8056 1.00201 11.6226 1.05428 11.4641 1.15289C11.3056 1.2515 11.1778 1.39252 11.0953 1.55998L8.24228 7.33998Z"
            fill="#9686F6"
        />
    </svg>
);
