import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";

import { packagesContent } from "constants/index";
import { PricingPackageItem } from "./common/pricing-package-item";
import { SectionId } from "enums";
import { PackageItemProps } from "types";
import { PAYMENT } from "router/constants";

export const Pricing: FC = () => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const onSubscribeButtonClick = (href: string) => navigate(PAYMENT + href);

    const renderPricingItem = (item: PackageItemProps) => (
        <PricingPackageItem {...item} key={item.price} onButtonClick={() => onSubscribeButtonClick(item.href)} />
    );

    return (
        <section
            id={SectionId.PRICING}
            aria-label="Pricing and Packages"
            className="px-20 py-16 relative overflow-hidden mt-2 lg:w-full lg:py-14 lg:px-4">
            <Zoom triggerOnce>
                <h1 className="font-outfit text-5xl font-bold mb-8 text-center text-white-heaven lg:text-4xl">
                    {t("pricing.choose")}
                </h1>
                <ul
                    aria-label="Subscription packages"
                    className="list-none max-w-7xl w-full grid grid-cols-4 lg:grid-cols-2 sm:grid-cols-1 gap-8 mb-14">
                    {packagesContent.map(renderPricingItem)}
                </ul>
            </Zoom>
        </section>
    );
};
