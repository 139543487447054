import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { UseFormRegister, FieldErrors } from "react-hook-form";

interface IFormValues {
    Name: string;
    Email: string;
    Message: string;
}

type InputProps = {
    label: string;
    register: UseFormRegister<IFormValues>;
    required: boolean;
    className?: string;
    errors: FieldErrors<IFormValues>;
};

export const Input = ({ register, required, label, className, errors }: InputProps) => {
    const { t } = useTranslation();
    const defaultStyle =
        "bg-shoe-wax rounded-lg font-outfit text-base px-4 pb-2 pt-6 text-white-heaven outline-none w-full mb-3";

    return (
        <div className={twMerge("relative w-full", className)}>
            {errors[label as keyof IFormValues] && (
                <span className="font-outfit text-xs absolute right-4 top-2 text-red-500">
                    {errors[label as keyof IFormValues]?.message}
                </span>
            )}
            <label className="absolute text-xs text-white-heaven text-opacity-60 left-4 top-2">
                {t(label)}
            </label>
            {label === t("contact.message") ? (
                <textarea
                    {...register(t(label), {
                        required,
                        minLength: { value: 10, message: t("contact.min10") }
                    })}
                    className={twMerge(defaultStyle, "h-28 resize-none")}
                />
            ) : label === t("contact.email") ? (
                <input
                    {...register(t(label), {
                        required,
                        pattern: {
                            value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                            message: t("contact.validEmail")
                        }
                    })}
                    className={defaultStyle}
                />
            ) : (
                <input
                    {...register(t(label), {
                        required,
                        minLength: { value: 2, message: t("contact.min2") }
                    })}
                    className={defaultStyle}
                />
            )}
        </div>
    );
};
