import { FC } from "react";
import { twMerge } from "tailwind-merge";
import { useNavigate, useLocation } from "react-router-dom";

import { NavigationLink } from "./navigation-link.component";
import { HOME_PATH } from "router/constants";
import { navigationLinkContent } from "constants/index";
import { NavigationLinkProps } from "types";
import { SectionId } from "enums";

interface Props {
    listClassName?: string;
    itemClassName?: string;
    hrefStyle?: string;
    isFooter?: boolean;
    activeLinkId: SectionId | null;
    onLinkClick: (id: SectionId) => void;
}

const termsLink = {
    name: "navigation.terms",
    id: SectionId.TERMS,
    href: SectionId.TERMS
};

export const NavigationList: FC<Props> = ({
    listClassName,
    itemClassName,
    activeLinkId,
    onLinkClick,
    isFooter = false
}) => {
    const navigate = useNavigate();

    const handleClick = (id: SectionId) => () => {
        navigate(id === SectionId.HOME_PAGE ? HOME_PATH : `/#${id}`);

        onLinkClick(id);
    };

    const renderNavigationLink = (link: NavigationLinkProps) => (
        <NavigationLink
            key={link.id}
            className={twMerge(activeLinkId === link.id && "text-opacity-100", itemClassName)}
            onClick={handleClick(link.id)}
            {...link}
        />
    );

    return (
        <ul aria-label="Navigation links" className={twMerge("list-none", listClassName)}>
            {navigationLinkContent.map(renderNavigationLink)}
            {isFooter && (
                <NavigationLink
                    key={termsLink.id}
                    className={itemClassName}
                    onClick={handleClick(termsLink.id)}
                    {...termsLink}
                />
            )}
        </ul>
    );
};
