import { FC, SVGProps } from "react";

export const ChromeIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={32} {...props}>
        <path
            d="M3.73535 10.759L8.99402 19.867C9.82054 21.3656 11.106 22.5595 12.6614 23.2732C14.2169 23.987 15.9603 24.183 17.6353 23.8323L14.5073 29.251C7.84602 28.5097 2.66602 22.8603 2.66602 16.0003C2.66602 14.139 3.04735 12.3683 3.73535 10.759ZM28.2233 10.667C28.9575 12.3491 29.3352 14.165 29.3327 16.0003C29.3327 22.8243 24.206 28.451 17.594 29.2403L22.858 20.1203C23.5833 18.9177 23.9993 17.507 23.9993 16.0003C24.0021 14.032 23.2764 12.1322 21.962 10.667H28.2233ZM15.9993 10.667C17.4138 10.667 18.7704 11.2289 19.7706 12.2291C20.7708 13.2293 21.3327 14.5858 21.3327 16.0003C21.3327 17.4148 20.7708 18.7714 19.7706 19.7716C18.7704 20.7718 17.4138 21.3337 15.9993 21.3337C14.5849 21.3337 13.2283 20.7718 12.2281 19.7716C11.2279 18.7714 10.666 17.4148 10.666 16.0003C10.666 14.5858 11.2279 13.2293 12.2281 12.2291C13.2283 11.2289 14.5849 10.667 15.9993 10.667ZM15.9993 2.667C18.0696 2.66544 20.1116 3.14669 21.9633 4.07252C23.8149 4.99834 25.4251 6.34323 26.666 8.00033H15.9993C14.3147 8.00052 12.6731 8.53224 11.3083 9.51977C9.94348 10.5073 8.92504 11.9003 8.39802 13.5003L5.27002 8.083C6.50856 6.40225 8.125 5.03631 9.98881 4.09549C11.8526 3.15467 13.9115 2.66534 15.9993 2.667Z"
            fill="#FEFEFE"
            fillOpacity="0.4"
        />
    </svg>
);
