import { LOGIN_PATH, CREATE_ACCOUNT_PATH } from "router/constants";
import { ButtonType, SectionId } from "enums";
import { NavigationLinkProps, PackageItemProps, ManageAccountButtonsProps } from "types";

export const navigationLinkContent: NavigationLinkProps[] = [
    {
        name: "navigation.whyWe",
        id: SectionId.WHYWE
    },
    {
        name: "navigation.pricing",
        id: SectionId.PRICING
    },
    {
        name: "navigation.benefits",
        id: SectionId.BENEFITS
    },
    {
        name: "navigation.contacts",
        id: SectionId.CONTACTS
    }
];

export const manageAccountButtons: ManageAccountButtonsProps[] = [
    {
        name: "navigation.login",
        path: LOGIN_PATH,
        type: ButtonType.OUTLINED
    },
    {
        name: "navigation.tryTest",
        path: CREATE_ACCOUNT_PATH,
        type: ButtonType.FILLED
    }
];

export const packagesBenefits = [
    "pricing.benefits.devicesPerUser",
    "pricing.benefits.hdChannels",
    "pricing.benefits.countries",
    "pricing.benefits.categories",
    "pricing.benefits.vodLibrary"
];

export const packagesContent: PackageItemProps[] = [
    {
        name: "pricing.monthPlan",
        price: 24.99,
        month: 1,
        list: "pricing.list1",
        href: "/?packId=2&pr=16"
    },
    {
        name: "pricing.monthPlan",
        price: 51.99,
        month: 3,
        list: "pricing.list2",
        href: "/?packId=3&pr=16"
    },
    {
        name: "pricing.monthPlan",
        price: 89.99,
        month: 6,
        list: "pricing.list3",
        href: "/?packId=4&pr=16"
    },
    {
        name: "pricing.yearPlan",
        price: 159.99,
        list: "pricing.list4",
        href: "/?packId=5&pr=16"
    }
];

export const createAccountIframePath = "/register";

export const footerInfo = [
    "Cel Mai Media Inc",
    "11555 Medlock Bridge Rd, Suite100 Johns Creek, GA 30097",
    "+ 1 (866) 7554603"
];

export const formInputs = ["Name", "Email", "Message"];
