import { FC, SVGProps } from "react";

export const ArrowIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg" width={10} {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.77625 6.89611C8.64375 6.89603 8.5167 6.84335 8.423 6.74966C8.32931 6.65597 8.27664 6.52892 8.27656 6.39642L8.27656 1.94636L1.58733 8.63559C1.49356 8.72936 1.36638 8.78204 1.23377 8.78204C1.10116 8.78204 0.973988 8.72936 0.88022 8.63559C0.786452 8.54182 0.733774 8.41465 0.733774 8.28204C0.733774 8.14943 0.786452 8.02225 0.88022 7.92848L7.56945 1.23925L3.11939 1.23925C3.05229 1.24162 2.98539 1.23045 2.9227 1.20641C2.86001 1.18236 2.8028 1.14594 2.75449 1.0993C2.70618 1.05267 2.66776 0.996781 2.64152 0.934975C2.61528 0.87317 2.60175 0.806711 2.60175 0.739566C2.60175 0.67242 2.61528 0.605962 2.64152 0.544157C2.66776 0.482351 2.70618 0.426465 2.75449 0.379831C2.8028 0.333197 2.86001 0.29677 2.9227 0.272724C2.98539 0.24868 3.05229 0.237508 3.11939 0.239877L8.77625 0.239877C8.90875 0.23996 9.0358 0.292632 9.12949 0.386324C9.22318 0.480016 9.27585 0.607065 9.27593 0.739566L9.27593 6.39642C9.27585 6.52892 9.22318 6.65597 9.12949 6.74966C9.0358 6.84335 8.90875 6.89603 8.77625 6.89611Z"
            fill="#0A0A0A"
        />
    </svg>
);
