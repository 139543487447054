import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { ArrowIcon } from "assets/icons/arrow.icon";

import { ButtonType } from "enums";

interface IProps extends PropsWithChildren {
  className?: string;
  type?: "button" | "submit";
  onClick?: () => void;
  arialLabel?: string;
  buttonType?: string;
  arrow?: boolean;
}

export const Button = ({
  className,
  type = "button",
  children,
  onClick,
  arialLabel,
  buttonType = ButtonType.OUTLINED,
  arrow = false
}: IProps) => (
  <button
    aria-label={arialLabel}
    type={type}
    className={twMerge(
      "w-full h-11 px-4 border border-white-heaven text-white-heaven rounded-lg font-outfit text-sm text-nowrap font-semibold leading-4 cursor-pointer duration-300 flex items-center",
      buttonType === ButtonType.OUTLINED &&
      "hover:border-primary hover:text-primary",
      buttonType === ButtonType.FILLED &&
      "bg-primary border-primary text-thamar-black hover:bg-medium-slate hover:border-medium-slate hover:text-thamar-black",
      className
    )}
    onClick={onClick}
  >
    {children}
    {arrow && <ArrowIcon className="ml-2" />}
  </button>
);
