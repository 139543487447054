import { FC, SVGProps } from "react";

export const WindowsIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" width={32} {...props}>
        <path
            d="M28 17.333V27.245C28 27.5136 27.9459 27.7794 27.8409 28.0266C27.7359 28.2738 27.5822 28.4974 27.389 28.6839C27.1957 28.8704 26.9669 29.0161 26.7161 29.1123C26.4653 29.2085 26.1978 29.2531 25.9293 29.2436L25.752 29.229L16 28.0103V17.333H28ZM13.3333 17.333V27.677L6.336 26.8023C5.69094 26.7217 5.09754 26.4082 4.66736 25.9209C4.23719 25.4335 3.99986 24.8057 4 24.1556V17.333H13.3333ZM25.752 2.77031C26.0185 2.73702 26.289 2.75774 26.5473 2.83124C26.8056 2.90474 27.0465 3.02952 27.2555 3.19813C27.4646 3.36674 27.6375 3.57572 27.7641 3.81262C27.8906 4.04952 27.9681 4.30947 27.992 4.57698L28 4.75431V14.6663H16V3.98898L25.752 2.77031ZM13.3333 4.32231V14.6663H4V7.84364C3.99986 7.19357 4.23719 6.56583 4.66736 6.07844C5.09754 5.59105 5.69094 5.27759 6.336 5.19698L13.3333 4.32231Z"
            fill="#FEFEFE"
            fillOpacity="0.4"
        />
    </svg>
);
