import { FC, SVGProps } from "react";

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg" width={30} {...props}>
        <path
            d="M29.1809 10.6124C28.0875 19.9778 24.0296 27.7882 16.8157 33.9016C15.321 35.169 13.9152 35.2009 12.4773 33.9513C5.3486 27.742 1.02449 19.9849 0.0730397 10.4988C-0.164822 8.13797 0.428057 7.54864 2.58301 6.55104C5.94858 4.97831 9.28219 3.32038 12.5235 1.51335C13.9897 0.700357 15.0938 0.661305 16.5849 1.4885C19.883 3.32038 23.2841 4.96766 26.6993 6.56524C28.7975 7.55574 29.3194 8.22672 29.1809 10.6124ZM27.3774 11.3722C27.3206 8.38648 27.6543 8.92966 25.2935 7.822C22.1445 6.33448 19.0665 4.70495 15.9991 3.05057C15.0903 2.5642 14.4228 2.39379 13.4181 2.92987C9.98512 4.7724 6.45625 6.43389 3.01968 8.25512C2.72544 8.43312 2.47296 8.67237 2.27938 8.9566C2.08581 9.24084 1.95568 9.56341 1.89783 9.9024C1.71322 13.2147 2.59366 16.3389 3.92853 19.3459C6.14028 24.27 8.89877 28.8568 13.0986 32.4105C14.1246 33.2767 15.0619 33.2874 16.1021 32.4105C19.2511 29.8047 21.5196 26.5137 23.5184 23.0167C25.6485 19.3068 27.1964 15.3839 27.3774 11.3722Z"
            fill="#9686F6"
        />
        <path
            d="M27.3773 11.3722C27.1962 15.3839 25.6484 19.3068 23.5183 23.0309C21.5195 26.5279 19.2581 29.8189 16.102 32.4247C15.0618 33.2874 14.1245 33.2767 13.0985 32.4247C8.89865 28.8568 6.14017 24.2699 3.92841 19.3459C2.59354 16.3389 1.7131 13.2147 1.90126 9.9095C1.95911 9.57051 2.08924 9.24794 2.28281 8.9637C2.47639 8.67946 2.72887 8.44022 3.02312 8.26222C6.45968 6.43388 9.98855 4.7724 13.4216 2.93696C14.4263 2.40088 15.0937 2.58194 16.0025 3.05767C19.0663 4.71205 22.1443 6.34158 25.2933 7.82555C27.6542 8.92965 27.3205 8.38648 27.3773 11.3722ZM7.99336 25.8249C9.71519 27.7136 11.1885 29.4781 12.8287 31.0898C14.1955 32.4353 15.1825 32.4389 16.4747 31.0117C18.3817 28.9994 20.1379 26.8492 21.729 24.5788C24.5904 20.3186 26.6992 15.7034 26.3975 10.3781C26.369 9.89885 26.1951 9.22077 25.8614 9.03261C25.5277 8.84445 24.8283 9.12846 24.5159 9.43378C19.077 14.7839 13.6701 20.1801 7.99336 25.8249ZM5.73545 21.6144L20.6888 6.7605C19.1622 5.87295 17.5824 4.80435 15.8712 4.03396C15.0369 3.71166 14.111 3.72055 13.2831 4.05882C10.1164 5.50019 7.00641 7.07291 3.92841 8.69534C3.63716 8.88634 3.38931 9.13643 3.20094 9.42939C3.01256 9.72234 2.88787 10.0516 2.83496 10.3959C2.44799 14.5141 3.829 18.185 5.73545 21.6144Z"
            fill="#FFDC22"
        />
        <path
            d="M7.99341 25.8249C13.6737 20.1802 19.077 14.7839 24.5159 9.4267C24.8283 9.12139 25.581 8.85867 25.8614 9.02553C26.1419 9.19239 26.3691 9.89177 26.3975 10.371C26.717 15.7141 24.5905 20.3115 21.729 24.5717C20.138 26.8422 18.3818 28.9923 16.4748 31.0046C15.1825 32.4247 14.1956 32.4247 12.8287 31.0828C11.1957 29.4781 9.71524 27.7136 7.99341 25.8249Z"
            fill="#9686F6"
        />
        <path
            d="M5.73548 21.6144C3.82904 18.1849 2.44802 14.5141 2.84209 10.4101C2.89229 10.0639 3.015 9.7322 3.2022 9.43672C3.38941 9.14123 3.63688 8.88859 3.92844 8.69533C6.99934 7.0587 10.1093 5.50017 13.276 4.0446C14.104 3.70634 15.0299 3.69745 15.8641 4.01975C17.5753 4.79014 19.1551 5.85874 20.6817 6.74628L5.73548 21.6144Z"
            fill="#9686F6"
        />
    </svg>
);
