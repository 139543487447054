import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { generateLink } from "utils/generate-link";
import { LinkModule } from "enums/index";

interface SendMessage {
    Name: string;
    Email: string;
    Message: string;
}

const sendMessage = async ({ Name, Email, Message }: SendMessage): Promise<SendMessage> => {
    const link = generateLink({
        module: LinkModule.PUBLIC,
        additionalPath: "/contactus"
    });
    const { data } = await axios.post<{ data: SendMessage }>(link, {
        Name: Name,
        Email: Email,
        Message: Message
    });
    return data.data;
};

export const useSendMessage = () => {
    const mutation = useMutation({
        mutationFn: sendMessage
    });

    return mutation;
};
