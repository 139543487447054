import { FC, SVGProps } from "react";

export const LockIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width={24} {...props}>
        <path
            d="M17.38 9.10001V7.51001C17.4197 6.60142 17.2284 5.69759 16.8239 4.88304C16.4194 4.06849 15.8149 3.36982 15.067 2.85237C14.3191 2.33492 13.4522 2.01559 12.5473 1.92424C11.6425 1.83288 10.7292 1.97249 9.893 2.33001C8.88703 2.75938 8.03584 3.48517 7.45287 4.41063C6.8699 5.33609 6.58286 6.41726 6.63 7.51001V9.10001C5.8651 9.15853 5.14857 9.49681 4.6173 10.0502C4.08603 10.6036 3.77727 11.3334 3.75 12.1V19.04C3.77613 19.8764 4.13298 20.6684 4.74227 21.242C5.35155 21.8157 6.1635 22.1242 7 22.1H17C17.8365 22.1242 18.6485 21.8157 19.2577 21.242C19.867 20.6684 20.2239 19.8764 20.25 19.04V12.1C20.223 11.3349 19.9156 10.6065 19.3864 10.0533C18.8572 9.50017 18.1431 9.16087 17.38 9.10001ZM8.13 7.51001C8.12743 7.00121 8.22601 6.49697 8.41999 6.02659C8.61398 5.55621 8.89951 5.12908 9.26 4.77001C9.98803 4.04469 10.9723 3.63516 12 3.63001C13.0282 3.63265 14.0136 4.04228 14.7407 4.76935C15.4677 5.49642 15.8774 6.48178 15.88 7.51001V9.07001H8.13V7.51001ZM15.47 18.91H8.57C8.30478 18.91 8.05043 18.8046 7.86289 18.6171C7.67536 18.4296 7.57 18.1752 7.57 17.91C7.57 17.6448 7.67536 17.3904 7.86289 17.2029C8.05043 17.0154 8.30478 16.91 8.57 16.91H15.51C15.7752 16.91 16.0296 17.0154 16.2171 17.2029C16.4046 17.3904 16.51 17.6448 16.51 17.91C16.51 18.1752 16.4046 18.4296 16.2171 18.6171C16.0296 18.8046 15.7752 18.91 15.51 18.91H15.47Z"
            fill="#9686F6"
        />
    </svg>
);
