import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Zoom } from "react-awesome-reveal";

import { Logo } from "./common/logo.component";
import { NavigationList } from "./common/navigation-list.component";
import { SectionContainer } from "./common/section-container.component";
import { scrollIntoElementById } from "utils/scroll-into-element-by-id";
import { HOME_PATH } from "router/constants";
import { SectionId } from "enums";
import { footerInfo } from "constants/index";

export const Footer: FC = () => {
    const location = useLocation();
    const hostName = window.location.hostname;

    const isHomePage = location.pathname === HOME_PATH;

    const [activeLinkId, setActiveLinkId] = useState<SectionId | null>(
        isHomePage
            ? location.hash
                ? (location.hash.replace("#", "") as SectionId)
                : SectionId.HOME_PAGE
            : null
    );

    useEffect(() => {
        if (location.hash) {
            const hashId = location.hash.replace("#", "") as SectionId;

            const searchedId = hashId || activeLinkId;

            scrollIntoElementById(searchedId, "auto");
            location.hash = "";
        }
    }, [activeLinkId]);

    useEffect(() => {
        if (!isHomePage) {
            setActiveLinkId(null);
        }
    }, [isHomePage]);

    const handleLinkClick = (id: SectionId) => {
        setActiveLinkId(id);
        scrollIntoElementById(id);
    };

    const renderInfo = (item: string) =>
        useMemo(
            () => (
                <li className="mb-3" key={item}>
                    <p className="text-white-heaven font-outfit text-sm">{item}</p>
                </li>
            ),
            []
        );
    return (
        <footer aria-label="Footer" className="w-full flex justify-center mt-2">
            <SectionContainer className="flex-row items-start max-w-360 py-8 lg:flex-col">
                <Zoom triggerOnce className="flex-1 lg:mb-8">
                    <Logo />
                </Zoom>

                <Zoom triggerOnce className="flex-1 h-full lg:mb-8">
                    <NavigationList
                        activeLinkId={activeLinkId}
                        onLinkClick={handleLinkClick}
                        isFooter={true}
                        itemClassName="mb-3 text-white-heaven text-opacity-100 font-outfit text-sm"
                        listClassName="gap-x-10 px-10 flex flex-col items-start lg:px-0"
                    />
                </Zoom>

                <Zoom triggerOnce className="flex-1 flex justify-end">
                    <ul className="flex flex-col">
                        {footerInfo.map(renderInfo)}
                        <li>
                            <a
                                className="font-outfit text-sm underline text-white-heaven"
                                href={`mailto:support@${hostName}`}>
                                support@{hostName}
                            </a>
                        </li>
                    </ul>
                </Zoom>
            </SectionContainer>
        </footer>
    );
};
