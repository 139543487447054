import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ShieldArrowIcon } from "assets/icons/shield-arrow.icon";

export const HeaderList: FC = () => {
    const { t } = useTranslation();
    const list = t("header.headerList").split(";");

    const renderList = useCallback(
        (item: string) => (
            <li className="font-rethink text-white-heaven text-base flex items-center mb-3 lg:text-sm" key={item}>
                <ShieldArrowIcon className="mr-2" />
                {item}
            </li>
        ),
        [t]
    );
    return <ul>{list.map(renderList)}</ul>;
};
