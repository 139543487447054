import { FC } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

interface Props {
    styles: string;
}

export const Limitations: FC<Props> = ({ styles }) => {
    const { t } = useTranslation();
    const list = t("terms.limitationsList").split(";");

    const renderLi = (item: string) => (
        <li className={styles} key={item}>
            {item}
        </li>
    );

    return (
        <>
            <p className={twMerge("mt-3", styles)}>{t("terms.maximumExtent")}</p>
            <ul className="list-decimal mt-6 pl-5">{list.map(renderLi)}</ul>
            <p className={twMerge("mt-6", styles)}>{t("terms.noEvent")}</p>
        </>
    );
};
